<template>
    <div id="pageList" class="WayBillCarList">
        <el-tabs v-model="pageList.queryParam.activeName" @tab-click="tabHandleClick">
            <el-tab-pane label="待确认" name="0"/>
            <el-tab-pane label="待装货" name="1"/>
            <el-tab-pane label="运输中" name="2"/>
            <el-tab-pane label="已签收" name="3"/>
            <el-tab-pane label="已取消" name="4"/>
        </el-tabs>
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">运单号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入运单号进行模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">车次号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.carOrder = validForbid(e)" placeholder="请输入车次号进行模糊匹配查询..." v-model="pageList.queryParam.carOrder" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">车牌号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.carId = validForbid(e)" placeholder="请输入车牌号进行模糊匹配查询..." v-model="pageList.queryParam.carId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">司机：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.driverId = validForbid(e)" placeholder="请输入司机进行模糊匹配查询..." v-model="pageList.queryParam.driverId" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.customerId = validForbid(e)" placeholder="请输入客户进行模糊匹配查询..." v-model="pageList.queryParam.customerId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">派车时间：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.time" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">要求装货时间：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.loadTime" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">要求送货时间：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.deliveryTime" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                </el-row>
            </template>
            <!--重写表格固定列-->
            <template v-slot:fixCol>
                <el-table-column fixed="left" header-align="center" align="center" label="操作" width="200" v-if="pageList.queryParam.activeName!=4">
                    <template #default="scope">
                        <div v-if="scope.row['F_STATUS']!=5">
                            <el-button @click="first(scope.row)" type="text">
                                <i class="el-icon-edit"></i>&nbsp;&nbsp;{{buildRowOperTitle(1)}}
                            </el-button>
                            <el-button @click="second(scope.row)" type="text">
                                <div style="color: #27ae60">
                                    <i class="iconfont icon-zaixianjiedan2mian"></i>&nbsp;&nbsp;{{buildRowOperTitle(2)}}
                                </div>
                            </el-button>
                            <el-button @click="third(scope.row)" type="text">
                                <div style="color: #f1c40f">
                                    <i class="iconfont icon-shouye"></i>&nbsp;&nbsp;{{buildRowOperTitle(3)}}
                                </div>
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button @click="pageListRef.editHandler(scope.row)" type="text">
                                <i class="el-icon-edit"></i>&nbsp;&nbsp;详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_CAR_ORDER" label="车次号" width="200">
                    <template #default="scope">
                        <el-badge value="异" type="danger" v-if="scope.row.F_EX_NUM>0">
                            <el-tag style="cursor: pointer" @click="pageListRef.editHandler(scope.row)">{{ scope.row.F_CAR_ORDER }}</el-tag>
                        </el-badge>
                        <el-tag v-else style="cursor: pointer" @click="pageListRef.editHandler(scope.row)">{{ scope.row.F_CAR_ORDER }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="F_TIME" label="派车时间"/>
                <el-table-column prop="F_STATUS_NAME" label="车次状态"/>
                <el-table-column prop="F_LOAD_TIME" label="要求装货时间"/>
                <el-table-column prop="F_DELIVERY_TIME" label="要求送货时间"/>
                <el-table-column prop="F_SALE_BILL_NAME" label="项目"/>
                <el-table-column prop="F_WAY_BILL_CODE" label="运单">
                    <template #default="scope">
                        <el-tag style="cursor: pointer" @click="viewWayBill(scope.row)">{{ scope.row.F_WAY_BILL_CODE }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="F_AUTH_CODE" label="委托单号"/>
                <el-table-column prop="F_CAR_ID" label="车辆"/>
                <el-table-column prop="F_DRIVER_ID" label="司机"/>
                <el-table-column prop="F_RZCDM" label="入驻车队码"/>
                <el-table-column prop="F_CUSTOMER_ID" label="客户"/>
                <el-table-column prop="F_YWCDM" label="业务车队码"/>
                <el-table-column prop="F_POINT_NUM" label="配送点位"/>
                <el-table-column prop="F_MILEAGE" label="最远里程"/>
                <el-table-column prop="F_SURE_LOAD_TIME" label="确认装货时间"/>
                <el-table-column prop="F_REASON" label="取消理由" v-if="pageList.queryParam.activeName==4"/>
            </template>
        </ListEngine>
    </div>

    <el-dialog v-model="exceptionDialogVisible" title="异常登记" :append-to-body="true" :close-on-click-modal="false">
        <div style="margin-right: 50px;">
            <el-form :ref="el=>refMap.set('formRef',el)" :model="form" :rules="rules" label-width="120px">
                <el-form-item label="登记人" prop="regPersonId">
                    <el-select :ref="el=>refMap.set('regPersonId',el)" v-model="form.regPersonId" placeholder="请选择登记人" style="width: 100%" clearable>
                        <el-option v-for="item in comboSelect({comboId:'regPersonId'})" :key="item.value" :label="item.label" :value="item.value"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="异常信息" prop="expContent">
                    <el-input @input="e => form.expContent = validForbid(e)" v-model="form.expContent" type="textarea" placeholder="请输入异常信息" maxlength="122" :disabled="disabled" clearable/>
                </el-form-item>
                <el-form-item label="处理结果" prop="dealResult">
                    <el-input @input="e => form.dealResult = validForbid(e)" v-model="form.dealResult" type="textarea" placeholder="请输入处理结果" maxlength="122" :disabled="disabled" clearable/>
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="exceptionDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitExpForm">确认</el-button>
          </span>
        </template>
    </el-dialog>

    <el-dialog v-model="sureSignDialogVisible" title="确认签收" :append-to-body="true" :close-on-click-modal="false">
        <div style="margin-right: 50px;">
            <Upload v-bind="uploadParams" :ref="el=>refMap.set('wayBillCarSureSign',el)"/>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="sureSignDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitSignForm">确认</el-button>
          </span>
        </template>
    </el-dialog>

    <el-dialog v-model="reasonDialogVisible" title="取消理由" :append-to-body="true" :close-on-click-modal="false">
        <div style="margin-right: 50px;">
            <el-form :ref="el=>refMap.set('cancelFormRef',el)" :model="cancelForm" :rules="cancelRules" label-width="120px">
                <el-form-item label="取消理由" prop="reason">
                    <el-input @input="e => cancelForm.reason = validForbid(e)" v-model="cancelForm.reason" type="textarea" placeholder="请输入取消理由" maxlength="122" clearable/>
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="reasonDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitCancelForm">确认</el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
    import WayBillCarCard from './WayBillCarCard';
    import WayBillCard from '../wayBill/WayBillCard';
    import ExRegisterList from '../exRegister/ExRegisterList';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
    export default defineComponent ({
        name: "WayBillCarList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            provide('uploadOwner', proxy);
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                exceptionDialogVisible:false,
                sureSignDialogVisible:false,
                reasonDialogVisible:false,
                disabled:false,
                operateRow:{},//当前正在操作的表格行对象
                exceptionRegType:'',//异常登记类别：运输中、已签收
                form: {
                    regPersonId: '',
                    expContent: '',
                    dealResult: ''
                },
                rules: {
                    regPersonId: [ {required: true, message: "请选择登录人", trigger: "blur" }],
                    expContent: [ {required: true, message: "请输入异常信息", trigger: "blur" }],
                    dealResult: [ {required: true, message: "请输入处理结果", trigger: "blur" }],
                },
                cancelForm: {
                    reason: ''
                },
                cancelRules: {
                    reason: [ {required: true, message: "请输入取消原因", trigger: "blur" }]
                },
                personData:[],//异常登记人员下拉
                uploadParams:{//确认收货，上传组件参数
                    saveType:1,
                    title:'签收图片',
                    autoUpload:false,
                    showFileList:true,
                    showInDialog:true,
                    listType:'picture-card',
                    accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
                    uploadType:'wayBillCarSureSign',
                },
                pageList: {
                    queryParam: {
                        activeName:0,
                        code:"",
                        carOrder:"",
                        carId:"",
                        driverId:"",
                        customerId:"",
                        time:"",
                        loadTime:'',
                        deliveryTime:''
                    },
                    // canPage:true,
                    reduceOthers:45,
                    modelComp: WayBillCarCard,
                    modelMethod: "/wayBillCar/pageData"
               }
            })
            onMounted(async ()=>{
                dataObj.pageListRef.getTbInst().setPagerHeight(140);
                // utils.$$str.merges({mergeColInfo:[{began:1,end:3,flag:false},{began:4,end:6,flag:true},{began:7,end:9,flag:false},{began:10,end:12,flag:true}]});
            })
            //构建人员下拉请求
            const buildPersonData=async ()=>{
                dataObj.personData= await utils.$$api.getPersons({});
            }
            //-----------------------------上传--------------------------------
            const buildUploadParams=(formData,params,uploadInst)=>{
                if((uploadInst.uploadParams.saveType)==0){
                    //不采用第三方文件服务器，formData才有append方法，否则formData就是一个普通的对象，没有append方法
                    formData.append('id',dataObj.operateRow['F_ID']);
                }else{
                    formData.id=dataObj.operateRow['F_ID'];
                }
            }
            //上传成功之后，需要把本次上传的图片文件追加到上传控件文件数组中，
            const afterResult=(res,uploadInst)=>{
                utils.$$lghdUtils.dealAfterUpload({res:res,uploadInst:uploadInst});
                dataObj.pageListRef.queryHandler();
            }
            //封装公共请求
            const sendRequest=async (options)=>{
                utils.$$tools.configBox({
                    msgContent:'确认执行该操作吗?',
                    fn:async ()=> {
                        let res = await utils.$$api.postRequest({ url: options.url, params: options.params});
                        if(res.result){
                            utils.$$tools.success();
                            dataObj.pageListRef.queryHandler();
                        }
                    }
                });
            }
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'carId'==params.comboId){
                        return dataObj.carData;
                    }
                    if(params && 'driverId'==params.comboId){
                        return dataObj.driverData;
                    }
                    if(params && 'customerId'==params.comboId){
                        return dataObj.customerData;
                    }
                    if(params && 'regPersonId'==params.comboId){
                        return dataObj.personData;
                    }
                }
            })
            const buildRowOperTitle=computed(()=>{
                return (index) => {
                    let activeItem=dataObj.pageList.queryParam.activeName;
                    switch (index) {
                        case 1:
                            if(activeItem==0)return '立即配载';//第1个tab的第1个btn
                            if(activeItem==1)return '立即配载';//第2个tab的第1个btn
                            if(activeItem==2 || activeItem==3)return '确认签收';//第3、4个tab的第1个btn
                        case 2:
                            if(activeItem==0)return '确认接单';//第1个tab的第2个btn
                            if(activeItem==1)return '确认装货';//第2个tab的第2个btn
                            if(activeItem==2 || activeItem==3)return '异常登记';//第3、4个tab的第2个btn（事实上是第一个btn，只是传入的值为2（case 2））
                        case 3:
                            if(activeItem==0 || activeItem==1)return '取消';//第1、2个tab的第3个btn
                            if(activeItem==2 || activeItem==3)return '异常记录';//第3、4个tab的第3个btn
                    }
                    return ''
                }
            })
            const first=async (row)=>{
                let activeItem=dataObj.pageList.queryParam.activeName;
                if(activeItem==2 || activeItem==3){//确认签收
                    dataObj.operateRow=row;//把当前正在操作的行赋值给operateRow，然后就可以在上传控件参数赋值那里可以拿到当前正在操作的行
                    dataObj.sureSignDialogVisible = true;//打开确认签收上传图片弹出框
                    //从后台查询已经上传的签收图片集合，赋值给上传控件
                    let res = await utils.$$api.postRequest({ url: '/wayBillCar/custom', params: {id:row['F_ID'],operateType:'signPics'}});
                    if(res.result)utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'wayBillCarSureSign',files:res.signPics,disabled:row['F_STATUS']==3,disabled:false});
                    dataObj.refMap.get('wayBillCarSureSign').setLimitNum(row.F_POINT_NUM);//在这里设置上传组件支持上传的文件数量（点位个数）
                }
                if(activeItem==0 || activeItem==1){
                    dataObj.operateRow=row;
                    dataObj.pageListRef.editHandler(row);
                }
            }
            const second=async (row)=>{
                let activeItem=dataObj.pageList.queryParam.activeName;
                let noteFlag=true;
                let params={};
                if(activeItem==0){//确认接单
                    params={activeName:activeItem,operateType:'sureJd',id:row['F_ID']};
                }else if(activeItem==1){//确认装货
                    params={activeName:activeItem,operateType:'sureLoad',id:row['F_ID']};
                }else if(activeItem==2 || activeItem==3){//异常登记
                    noteFlag=false;
                    if(dataObj.personData.length==0)await buildPersonData();
                    dataObj.form.regPersonId='';
                    dataObj.form.expContent='';
                    dataObj.form.dealResult='';
                    dataObj.exceptionDialogVisible=true;
                    dataObj.operateRow=row;
                    dataObj.exceptionRegType=activeItem;
                }
                if(noteFlag){
                    await sendRequest({url:'/wayBillCar/custom',params:params});
                }
            }
            const third=async (row)=>{
                let activeItem=dataObj.pageList.queryParam.activeName;
                if(activeItem==2 || activeItem==3) {//异常记录
                    await utils.$$lghdUtils.buildCommonDialog({from:'wayBillCarList',wayBillCarId:row['F_ID'],title: "异常记录",dialogDiv:"ExRegisterList",
                        showFooterBtn:false,modelComp: ExRegisterList,proxy:proxy});
                }
                if(activeItem==0 || activeItem==1){//取消
                    // await sendRequest({url:'/wayBillCar/custom',params:{activeName:activeItem,operateType:'cancel',id:row['F_ID']}});
                    dataObj.operateRow=row;
                    dataObj.reasonDialogVisible = true;
                }
            }
            const tabHandleClick=(tab)=>{
                let tabName=tab.props.name;
                if(tabName==4){
                    if(dataObj.pageListRef.getTbInst().tbCols.fields.indexOf('F_REASON')==-1){
                        dataObj.pageListRef.getTbInst().tbCols.fields.push('F_REASON');
                        dataObj.pageListRef.getTbInst().tbCols.labels.push('取消理由');
                    }
                    dataObj.pageListRef.listParam.isShowFixCol=false;

                }else{
                    let index=dataObj.pageListRef.getTbInst().tbCols.fields.indexOf('F_REASON')
                    if(index>-1){
                        dataObj.pageListRef.getTbInst().tbCols.fields.splice(index,1);
                        dataObj.pageListRef.getTbInst().tbCols.labels.splice(index,1);
                    }
                    dataObj.pageListRef.listParam.isShowFixCol=true;
                }
                dataObj.pageListRef.queryHandler();
            }
            //提交异常登记信息
            const submitExpForm=()=>{
                dataObj.refMap.get('formRef').validate(async (valid) => {
                    if (valid) {
                        let url = '/wayBillCar/custom';
                        let params={exceptionRegType:dataObj.exceptionRegType,expContent:dataObj.form,id:dataObj.operateRow['F_ID'],operateType:'regException'};
                        let res = await utils.$$api.postRequest({ url: url, params: params});
                        if(res.result){
                            utils.$$tools.success();
                            dataObj.pageListRef.queryHandler();
                        }
                        dataObj.exceptionDialogVisible = false;
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            }
            //提交取消原因form
            const submitCancelForm=()=>{
                dataObj.refMap.get('cancelFormRef').validate(async (valid) => {
                    if (valid) {
                        let url = '/wayBillCar/custom';
                        let params={reason:dataObj.cancelForm.reason,id:dataObj.operateRow['F_ID'],operateType:'cancel'};
                        let res = await utils.$$api.postRequest({ url: url, params: params});
                        if(res.result){
                            utils.$$tools.success();
                            dataObj.pageListRef.queryHandler();
                        }
                        dataObj.reasonDialogVisible = false;
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            }
            //确认签收弹出框确认事件
            const submitSignForm=async ()=>{
                if(dataObj.refMap.get(dataObj.uploadParams.uploadType).curUploadNum==0){
                    proxy.$message({showClose: true, message: '请选择要上传的图片', type: 'info'});
                }else{
                    dataObj.refMap.get(dataObj.uploadParams.uploadType).uploadComp.submit();//手动提交上传控件
                    proxy.$message({showClose: true, message: '操作成功', type: 'success'});
                }
            }
            //查看运单
            const viewWayBill=async (row)=>{
                await utils.$$lghdUtils.buildCommonDialog({from:'WayBillCarList',id:row['F_WAY_BILL_ID'],title: "运单",dialogDiv:"WayBillCard",
                    modelComp: WayBillCard,proxy:proxy});
            }
            //删除图片之后(删除表格)
            const afterRemoveUploadFile=(file, fileList)=>{
                dataObj.pageListRef.queryHandler();
            }
            return{
                ...toRefs(dataObj),buildUploadParams,afterResult,comboSelect,buildRowOperTitle,first,second,third,tabHandleClick,submitExpForm,submitCancelForm,submitSignForm,viewWayBill,afterRemoveUploadFile
            }
        }
    });
</script>

<style scoped>
    .WayBillCarList{
        width: 100%;
    }
    /*.WayBillCarList ::v-deep(td){*/
    /*    height: 40px;*/
    /*    line-height: 40px;*/
    /*    text-align: left;*/
    /*    margin-top: 5px;*/
    /*    vertical-align: center;*/
    /*}*/
    .WayBillCarList ::v-deep(.el-badge){
        margin-top: 9px;
    }

    .WayBillCarList ::v-deep(.el-table tr){
        /*height: 62px;*/
    }
</style>