<template>
    <div id="pageList" class="ExRegisterList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">登记人：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('userId',el)" v-model="pageList.queryParam.userId" placeholder="请选择登记人" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'userId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_USER_ID" label="登记人"/>
                <el-table-column prop="F_CONTENT" label="异常信息"/>
                <el-table-column prop="F_DEAL_RESULT" label="处理结果"/>
                <el-table-column prop="F_TIME" label="添加时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, provide} from 'vue';
    export default defineComponent ({
        name: "ExRegisterList",
        props: {
            engineParams: {
                type: Object
            }
        },
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                personData:[],
                pageList: {
                    isShowFixCol:false,
                    queryParam: {
                        userId:"",
                        from:props.engineParams?props.engineParams.from:'',
                        wayBillCarId:props.engineParams?props.engineParams.wayBillCarId:''
                    },
                    modelMethod: "/exRegister/pageData"
               }
            })
            onMounted(async ()=>{
                buildPersonData();
            })
            const buildPersonData=async ()=>{
                dataObj.personData= await utils.$$api.getPersons({});
            }
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'userId'==params.comboId){
                        return dataObj.personData;
                    }
                }
            })

            return{
                ...toRefs(dataObj),comboSelect
            }
        }
    });
</script>

<style scoped>
    .ExRegisterList{
        width: 100%;
    }
</style>